


import libphonenumber from 'google-libphonenumber';

export default defineNuxtPlugin(() => ({
    
    provide: {
        toInternationalFormat: (n, country) => {
            const { PhoneNumberUtil, PhoneNumberFormat } = libphonenumber;
            const phoneUtil = PhoneNumberUtil.getInstance();
            if (n == "") return;
            const number = phoneUtil.parseAndKeepRawInput(n, country);
            return phoneUtil.format(number, PhoneNumberFormat.INTERNATIONAL);
        },
        toNationalFormat: (n) => {
            const { PhoneNumberUtil, PhoneNumberFormat } = libphonenumber;
            const phoneUtil = PhoneNumberUtil.getInstance();
            if (n == "") return;
            const number = phoneUtil.parse(n); // Parse international format to get country information
            return phoneUtil.format(number, PhoneNumberFormat.NATIONAL);
        }
    },
}));