// This is a specific plugin that handles multiple accounts and all headers, tokens to work with our DDB/RestAPI
export default defineNuxtPlugin(() => {
    const parseJwt = (token) => {
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    
        return JSON.parse(jsonPayload);
      };

    const  getCurrentMembership =() => {
      const token = sessionStorage.getItem("token");
      const account_id = sessionStorage.getItem("account_id");
      if (token) {
        // Use the provided parseJwt function
        const parsedToken = parseJwt(token);
        for (let item of parsedToken.membership){
          if (item.account_id == account_id){
            return item;
          }
        }
      }
      return null;
    }
    
    
    return {
        
      provide: {
        parseJwt,
        getCurrentMembership,
        getRole(){
          let object = getCurrentMembership();
          return object.role;
        },
        getCountry(){
          let object = getCurrentMembership();
          return object.country;
        },
        getCallCentersAsList(){
          let object = getCurrentMembership();
          let returnArray = [];
          for (let item of object.subroles){
            returnArray.push({name:item.name, value:item.callcenter_id});
          }
          return returnArray;
        },
        
        isTokenExpired(token){
          if (token){
            const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
            return parseJwt(token).exp < currentTime;
          }
        },

        translate: async (text, language) => {
          const { $post } = useNuxtApp();
          let translation = await $post(`/api/protected/services/translate`, {
            text,
            language
          });
          return translation;
        },
        translateSchedule: async (scheduleExplaination) => {
          const { $post } = useNuxtApp();
          let response = await $post(`/api/protected/services/schedule-translate`, { text: scheduleExplaination });
          return response;
        },
        prepareSchedule: async (schedule) => {
          function doubleDigit(d) {
            return d > 9 ? d : "0" + d;
          }
  
          let text = "";
  
          for (let day in schedule) {
            if (schedule[day].active) {
              text += `${day}: Open from ${doubleDigit(schedule[day].start.hours)}:${doubleDigit(schedule[day].start.minutes)} to ${doubleDigit(schedule[day].end.hours)}:${doubleDigit(schedule[day].end.minutes)} `;
              if (schedule[day].no_lunch) {
                text += " no lunch break\n ";
              } else {
                text += ` lunch from ${doubleDigit(schedule[day].break_start.hours)}:${doubleDigit(schedule[day].break_start.minutes)} to ${doubleDigit(schedule[day].break_end.hours)}:${doubleDigit(schedule[day].break_end.minutes)}  \n`;
              }
            } else {
              text += `${day}: closed\n`;
            }
          }
          return text;
        },
        prepareScheduleArray: async (schedule) => {
          function doubleDigit(d) {
            return d > 9 ? d : "0" + d;
          }
  
          let result = [];
  
          for (let day in schedule) {
            if (schedule[day].active) {
              let text = `Open from ${doubleDigit(schedule[day].start.hours)}:${doubleDigit(schedule[day].start.minutes)} to ${doubleDigit(schedule[day].end.hours)}:${doubleDigit(schedule[day].end.minutes)} `;
              if (schedule[day].no_lunch) {
                text += " no lunch break\n ";
              } else {
                text += ` lunch from ${doubleDigit(schedule[day].break_start.hours)}:${doubleDigit(schedule[day].break_start.minutes)} to ${doubleDigit(schedule[day].break_end.hours)}:${doubleDigit(schedule[day].break_end.minutes)}  \n`;
              }
              result.push({ day, text });
            } else {
              result.push({ day, text: 'closed' });
            }
          }
          return result;
        },
        tts: async (text, languageCode, voice, filename = "") => {
          const { $post } = useNuxtApp();
          let body = { text, languageCode, voice };
          if (filename !== "") body.filename = filename;
          let tts_response = await $post(`/api/protected/services/tts`, body);
          return tts_response.data;
        },
        toHumanDate: (ts,timeStyle="short") => {
          const date = new Date(ts * 1000)
          const humanFriendlyDate = date.toLocaleString(undefined, {
              dateStyle: 'medium', // You can change this to 'full', 'long', 'short' as per your preference
              timeStyle, // Includes the time in the format
          });
          return humanFriendlyDate
        },
        toHumanDateFromISO: (isoDate) => {
          const date = new Date(isoDate); // Convert ISO date string to Date object
          const humanFriendlyDate = date.toLocaleString(undefined, {
            dateStyle: 'medium', // You can use 'full', 'long', 'short', etc.
            timeStyle: 'short', // Includes the time
          });
          return humanFriendlyDate;
        },
        shortHumanFriendlyDuration: (seconds) => {
          // Calculate hours, minutes, and seconds
         const hours = Math.floor(seconds / 3600);
         const minutes = Math.floor((seconds % 3600) / 60);
         const remainingSeconds = seconds % 60;
  
         // Build the short format (e.g., "1h 2m 5s")
         const hoursDisplay = hours > 0 ? `${hours}h` : '';
         const minutesDisplay = minutes > 0 ? `${minutes}m` : '';
         const secondsDisplay = remainingSeconds > 0 ? `${remainingSeconds}s` : '';
         console.log([hoursDisplay, minutesDisplay, secondsDisplay])
         // Combine into a string, filtering out empty parts
         if (!hoursDisplay && !minutesDisplay && !secondsDisplay){
             return "-"
         } else {
             return [hoursDisplay, minutesDisplay, secondsDisplay].filter(Boolean).join(' ');
         }
        }
      }
    };
  });